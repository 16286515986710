// @ts-nocheck
import { t } from '@transifex/native'
import dayjs from 'dayjs'
import R from 'ramda'
import Rx from 'rxjs'

import * as ActionTypes from '../constants/actionTypes'
import buildAction from '../helpers/buildAction'
import { OpointTimestampToTimestamp } from '../opoint/common/time'
import {
  createReport,
  deleteReport,
  deleteReportNotification,
  getReportsTagHistory,
  shareReport,
  REPORT_STEP_CREATE_REPORT,
} from '../opoint/reports/index'
import { search, searchTimestamps } from '../opoint/search/index'
import { sortTags, customSort, lastSort } from '../opoint/tags/index'
import { getSelectedArticles } from '../selectors/articlesSelectors'
import { getReportContacts } from '../selectors/contactSelectors'
import { getProfileById } from '../selectors/profilesSelectors'
import {
  getAutoTranslate,
  isXlsTemplate,
  getCheckedTimestamps,
  getDeletedArticles,
  getEndDate,
  getFooter,
  getReportHistoryObject,
  getOpenedReport,
  getPreface,
  getUpdateSoMe,
  getSearchItems,
  getShareData,
  getReportObject,
  getSortedIDs,
  getAllSortedIDs,
  getSource,
  getStartDate,
  getStep,
  getTemplate,
  getTitle,
  useReportHistory,
} from '../selectors/reportsSelector'
import { getMainSearchLine } from '../selectors/searchSelectors'
import {
  getOpointLocale,
  getAutoTranslateSearchParams,
  getGroupingEnabled,
  getSimilarArticlesVisible,
} from '../selectors/settingsSelectors'
import { getTagById } from '../selectors/tagsSelectors'
import { getDefaultTemplate, getTemplateModules } from '../selectors/templatesSelectors'

import { logOutOnExpiredToken, serverIsDown } from './epicsHelper'
import { throwErrorOnSearchdFailure } from './searchEpics'

const reportsSourceEpic = (action$: any) =>
  action$
    .filter(({ type, payload }) => type === ActionTypes.REPORTS_SOURCE && payload.type === 'tag')
    ?.map(({ payload }) => buildAction(ActionTypes.REPORTS_HISTORY_FETCH, payload))

const reportsTitleEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.REPORTS_SOURCE),
    action$.ofType(ActionTypes.TAGS_FETCH_SUCCESS).take(1),
    action$.ofType(ActionTypes.PROFILES_FETCH_SUCCESS).take(1),
  )?.map(
    ([
      {
        payload: { type, id },
      },
    ]) => {
      let title = t('Report')
      switch (type) {
        case 'tag':
          title = getTagById(id)(getState()).name
          break
        case 'profile':
          const profileName = getProfileById(id)(getState())?.name
          title = profileName ?? title
          break
        case 'current_search':
          title = t('Current search')
          break
        case 'selected_articles':
          title = t('Selected articles')
          break
        default:
          title = t('Report') // todo
      }

      return buildAction(ActionTypes.REPORTS_TITLE, { title })
    },
  )

const reportsOpenModalEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.REPORTS_MODAL_OPEN),
    action$.ofType(ActionTypes.TEMPLATES_FETCH_SUCCESS).take(1),
  ).switchMap(() => {
    const defaultTemplate = getDefaultTemplate(getState())

    return Rx.Observable.of(buildAction(ActionTypes.REPORTS_TEMPLATE, { templateId: defaultTemplate?.id }))
  })

const reportUpdateSoMeMetadata = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.REPORTS_CREATE)
    .switchMap(({ payload: { groupidentical, updatedSoMe, updateError } = {} }) => {
      const state = getState()
      const updateSoMe = getUpdateSoMe(state)
      const searchline = getMainSearchLine(state)
      const selectedArticles = R.map(
        (article: any) => ({
          id_article: article.id_article,
          id_site: article.id_site,
          time: new Date(article.unix_timestamp * 1000),
        }),
        getSelectedArticles(state),
      )

      const requiredSearchItem: SearchItem = {
        linemode: 'R',
        searchline,
      }

      if (!updateSoMe || updatedSoMe || updateError) {
        return Rx.Observable.empty()
      }

      const params = { templateId: getTemplate(state) }
      const searchParams = {
        groupidentical,
        newest: dayjs(getEndDate(state)).unix(),
        oldest: dayjs(getStartDate(state)).unix(),
        ...(getAutoTranslate(state) ? getAutoTranslateSearchParams(state) : {}),
        allmeta: true,
        update_social_meta: updateSoMe,
        update_social_meta_max_wait: 0,
        requestedarticles: 60000,
      }

      if (selectedArticles.length > 0) {
        searchParams.articles = selectedArticles
      }

      // In order to update SoMe metadata, we're doing two search requests.
      // First one to start the update of all articles in the set
      // Second one to get the article set, with the updated data.
      return Rx.Observable.of(
        search(
          selectedArticles.length > 0 ? [requiredSearchItem] : getSearchItems(state),
          searchParams,
          params,
          getOpointLocale(state),
        ),
      )
        .delay(3000)
        .switchMap(() => {
          return Rx.Observable.fromPromise(
            search(
              selectedArticles.length > 0 ? [requiredSearchItem] : getSearchItems(state),
              searchParams,
              params,
              getOpointLocale(state),
            ),
          )
            .switchMap(throwErrorOnSearchdFailure)
            .switchMap((response) =>
              Rx.Observable.of(
                buildAction(ActionTypes.UPDATED_SOME_META_DATA_SUCCESS, { response }),
                buildAction(ActionTypes.REPORTS_UPDATE_SOME_META, { updateSoMe: false }),
                buildAction(ActionTypes.REPORTS_CREATE, { groupidentical, updatedSoMe: true }),
              ),
            )
            .catch(logOutOnExpiredToken)
            .catch(() =>
              Rx.Observable.of(
                buildAction(ActionTypes.UPDATED_SOME_META_DATA_FAILURE),
                buildAction(ActionTypes.REPORTS_UPDATE_SOME_META, { updateSoMe: false }),
                buildAction(ActionTypes.REPORTS_CREATE, { groupidentical, updatedSoMe: false, updateError: true }),
              ),
            )
        })
    })

const reportsLoadEpic = (action$: any, { getState }: any) =>
  action$
    .ofType(ActionTypes.REPORTS_ARTICLES)
    .switchMap(({ payload: { context, groupidentical } = {} }) => {
      const state = getState()

      const params = { templateId: getTemplate(state) }
      const searchParams = {
        context,
        groupidentical,
        ...(getAutoTranslate(state) ? getAutoTranslateSearchParams(state) : {}),
      }
      const { type, id } = getSource(state) || {}

      if (!type) {
        return Rx.Observable.empty()
      }

      let backendData$
      switch (type) {
        case 'tag':
        case 'profile':
        case 'current_search':
          backendData$ = Rx.Observable.defer(() =>
            useReportHistory(state)
              ? // tag using history (timestamps)
                searchTimestamps(
                  {
                    tagId: id,
                    stimestamps: getCheckedTimestamps(state),
                  },
                  { ...searchParams, requestedarticles: 300 },
                  params,
                )
              : // tag or profile (current_search) by date
                search(
                  getSearchItems(state),
                  {
                    ...searchParams,
                    newest: dayjs(getEndDate(state)).unix(),
                    oldest: dayjs(getStartDate(state)).unix(),
                    requestedarticles: type === 'tag' ? 300 : 15,
                  },
                  params,
                  getOpointLocale(state),
                ),
          )
          break
        case 'selected_articles':
          backendData$ = Rx.Observable.of({
            searchresult: {
              document: getSelectedArticles(state),
            },
            alreadyPreprocessed: true,
          })
          break
        default:
          console.error('Wrong type!') /* eslint-disable-line no-console */
      }

      if (type === 'tag') {
        // set mark that for newer articles then last sorted timestamp
        // this should be IMHO at the API side
        const lastSorted$ = Rx.Observable.defer(() => lastSort(id))

        return Rx.Observable.combineLatest(lastSorted$, backendData$).switchMap(
          ([{ unixTimestamp }, { searchresult }]) => {
            const isNew = (a) => {
              const tags = R.path(['tags'], a)
              if (!tags) {
                return false
              }

              const { set } = R.find(R.propEq('id', id))(tags)

              return OpointTimestampToTimestamp(set) > unixTimestamp
            }

            return Rx.Observable.of(
              buildAction(ActionTypes.REPORTS_ARTICLES_SUCCESS, {
                searchresult: R.evolve(
                  {
                    document: R.map((document) => ({
                      groupId: document.sort_group_ref,
                      newSinceLastSorted: isNew(document),
                      document,
                    })),
                  },
                  searchresult,
                ),
              }),
            )
          },
        )
      }

      return backendData$?.map(({ searchresult, alreadyPreprocessed }) =>
        buildAction(ActionTypes.REPORTS_ARTICLES_SUCCESS, {
          searchresult: R.evolve(
            {
              document: R.map((document) => ({
                groupId: document.sort_group_ref,
                document,
              })),
            },
            searchresult,
          ),
          alreadyPreprocessed, // articles
        }),
      )
    })
    .catch(logOutOnExpiredToken)
    .catch(serverIsDown)
    .catch((error) => Rx.Observable.of(buildAction(ActionTypes.REPORTS_ARTICLES_FAILURE, { error })))

const reportLoadInitialEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.REPORTS_LOAD_INITIAL_ARTICLES)
    .switchMap(({ payload: { groupidentical } = {} }) =>
      Rx.Observable.of(buildAction(ActionTypes.REPORTS_ARTICLES, { groupidentical })),
    )

const reportsHistoryEpic = (action$: any) =>
  action$.ofType(ActionTypes.REPORTS_HISTORY_FETCH).switchMap(({ payload: { id } }) =>
    Rx.Observable.fromPromise(getReportsTagHistory(id))
      ?.map(({ response }) => buildAction(ActionTypes.REPORTS_HISTORY_FETCH_SUCCESS, response))
      .retry(3)
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.REPORTS_HISTORY_FETCH_FAILURE))),
  )

const reportsHistorySuccessEpic = (action$: any) =>
  action$
    .ofType(ActionTypes.REPORTS_HISTORY_FETCH_SUCCESS)
    .switchMap(() => Rx.Observable.of(buildAction(ActionTypes.REPORTS_ARTICLES, { groupidentical: true })))

const reportsTagSortEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_SORT_TAG).switchMap(({ payload: { sortOrder, sortType, groupidentical } }) => {
    const state = getState()
    const params = useReportHistory(state)
      ? { stimestampUsed: getCheckedTimestamps(state) }
      : {
          fromTimestamp: dayjs(getStartDate(state)).unix(),
          toTimestamp: dayjs(getEndDate(state)).unix(),
        }

    return Rx.Observable.fromPromise(sortTags(getSource(state).id, { sortOrder, sortType, ...params }))
      ?.map(() => buildAction(ActionTypes.REPORTS_ARTICLES, { groupidentical }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.PORTAL_ERROR, { error: 'REPORTS_SORT_TAG_ERROR' })))
  })

const reportsStepEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_STEP).switchMap(({ payload }) => {
    const state = getState()

    return Rx.Observable.of(
      getSource(state)?.type === 'tag' && getStep(state) === 2 && getSortedIDs(state) && getSortedIDs(state).length
        ? buildAction(ActionTypes.REPORTS_CUSTOM_SORT, payload)
        : buildAction(ActionTypes.REPORTS_STEP_SUCCESS, payload),
    )
  })

const reportsSaveCustomSortOnExitEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_MODAL_CLOSE).switchMap(({ payload }) => {
    const state = getState()

    return getSource(state) !== null &&
      getSource(state).type === 'tag' &&
      getStep(state) === 2 &&
      getSortedIDs(state) &&
      getSortedIDs(state).length
      ? Rx.Observable.of(buildAction(ActionTypes.REPORTS_CUSTOM_SORT, payload))
      : Rx.Observable.of()
  })

const reportsCustomSortEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_CUSTOM_SORT).switchMap(({ payload }) => {
    const state = getState()

    const customSort$ = Rx.Observable.fromPromise(customSort(getSource(state).id, getAllSortedIDs(state)))
    // TODO this is a quick fix, however it's very bad that this epic
    // is tightly coupled with reportsStepEpic (REPORTS_STEP_SUCCESS)
    // Now we distinguish between click on a next step vs. click on X
    // in a modal by inspecting payload
    // We should do this more explicitly -> by checking a flag
    // or completely decoupling these epics.
    // Please get back to me on this one @olovor
    if (payload) {
      return customSort$
        .switchMap(() =>
          Rx.Observable.concat(
            Rx.Observable.of(buildAction(ActionTypes.REPORTS_CUSTOM_SORT_SUCCESS)),
            Rx.Observable.of(buildAction(ActionTypes.REPORTS_STEP_SUCCESS, payload)),
          ),
        )
        .catch(logOutOnExpiredToken)
        .catch(serverIsDown)
        .catch(() => Rx.Observable.of(buildAction(ActionTypes.PORTAL_ERROR, { error: 'REPORTS_CUSTOM_SORT' })))
    }

    return customSort$
      .switchMap(() => Rx.Observable.of(buildAction(ActionTypes.REPORTS_CUSTOM_SORT_SUCCESS)))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.PORTAL_ERROR, { error: 'REPORTS_CUSTOM_SORT' })))
  })

const reportsCreateEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_CREATE).switchMap(({ payload }) => {
    const state = getState()
    const { type, id } = getSource(state)
    const deletedArticles = getDeletedArticles(state)
    const locale = getOpointLocale(state)
    const { groupidentical, updatedSoMe } = payload
    const groupingHiddenAndDisabled = !getSimilarArticlesVisible(state)
    const updateSoMe = getUpdateSoMe(state)

    if (!updatedSoMe && updateSoMe) {
      return Rx.Observable.empty()
    }

    let subparams
    switch (type) {
      case 'tag':
        subparams = useReportHistory(state)
          ? {
              tagId: id,
              stimestamps: getCheckedTimestamps(state),
            }
          : {
              tagId: id,
              oldest: dayjs(getStartDate(state)).unix(),
              newest: dayjs(getEndDate(state)).unix(),
              expressions: getSearchItems(state),
            }
        break
      case 'current_search':
        subparams = {
          sortedArticles: getSortedIDs(state),
          oldest: dayjs(getStartDate(state)).unix(),
          newest: dayjs(getEndDate(state)).unix(),
          expressions: getSearchItems(state),
        }
        break
      case 'profile':
        subparams = {
          sortedArticles: getSortedIDs(state),
          oldest: dayjs(getStartDate(state)).unix(),
          newest: dayjs(getEndDate(state)).unix(),
          expressions: getSearchItems(state),
        }
        break
      case 'selected_articles':
        subparams = {
          articles: groupidentical ? getSortedIDs(state) : getAllSortedIDs(state),
        }
        break
      default:
        subparams = {}
    }

    const activeTemplate = getTemplate(state)
    const activeTemplateDetail = getTemplateModules(activeTemplate)(state)
    const includeOption = activeTemplateDetail?.MODULE_MAIN?.widgets?.find((item) => item.name === 'RT_INC_IDENTICAL')

    const isGroupingEnabled = groupidentical
    const isXlsTemplateCondition = !isXlsTemplate(state) && isGroupingEnabled
    const willIncludeOption = includeOption ? !(includeOption.value === '2') : isXlsTemplateCondition

    const params = {
      templateId: getTemplate(state),
      title: getTitle(state),
      locale,
      params: {
        ...(getAutoTranslate(state) ? getAutoTranslateSearchParams(state) : {}),
        excludearticles: deletedArticles,
        groupidentical: groupingHiddenAndDisabled ? false : willIncludeOption,
      },
    }

    const { html: prefaceHTML, text: prefaceText } = getPreface(state)
    const applyPreface = prefaceHTML || prefaceText
    applyPreface && (params.preface = `${prefaceHTML ? prefaceHTML : ''} ${prefaceText ? prefaceText : ''}`)

    const { html: footerHTML, text: footerText } = getFooter(state)
    const applyFooter = footerHTML || footerText
    applyFooter && (params.footer = `${footerHTML ? footerHTML : ''} ${footerText ? footerText : ''}`)

    const generateReport$ = Rx.Observable.defer(() => createReport({ ...params, ...subparams }))

    const generateReportTakingTooLong$ = Rx.Observable.of(buildAction(ActionTypes.REPORTS_CREATE_IS_TAKING_TOO_LONG))
      .delay(8000)
      .takeUntil(action$.ofType(ActionTypes.REPORTS_CREATE_IN_PROGRESS))

    return generateReport$
      .switchMap((reportObject) => {
        const actions = [buildAction(ActionTypes.REPORTS_CREATE_IN_PROGRESS, { reportObject })]
        // re-fetch history
        if (type === 'tag') {
          actions.push(buildAction(ActionTypes.REPORTS_HISTORY_FETCH, { type, id }))
        }

        actions.push(buildAction(ActionTypes.REPORTS_STEP, { step: 4 }))

        return Rx.Observable.of(...actions)
      })
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch((e) => Rx.Observable.of(buildAction(ActionTypes.REPORTS_CREATE_FAILURE)))
      .merge(generateReportTakingTooLong$)
  })

const reportsCreateFinishEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.NOTIFICATIONS_SOCKET_SUCCESS),
    action$.ofType(ActionTypes.REPORTS_CREATE_IN_PROGRESS),
  ).switchMap(
    ([
      {
        payload: { object },
      },
      {
        payload: {
          reportObject: { id: reportId },
        },
      },
    ]) => {
      if (object?.value?.id !== reportId) {
        return Rx.Observable.of()
      }

      return Rx.Observable.of(buildAction(ActionTypes.REPORTS_CREATE_SUCCESS, { reportObject: object?.value }))
    },
  )

const shareReportEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.SHARE_REPORT).switchMap(({ payload: { source } }) => {
    const state = getState()

    let reportObject
    switch (source) {
      case 'REPORTS_MODAL': // this should be placed inside const somewhere.
        reportObject = getReportObject(state) || getReportHistoryObject(state)
        break
      default:
        reportObject = getOpenedReport(state)
        break
    }

    const id = [reportObject.id]
    const message = getShareData(state).shareReportMessage
    const shareAttachment = getShareData(state).attachment
    const recipients = getReportContacts(state)?.map((contact) => {
      const {
        type,
        entity: { id, value },
      } = contact

      return R.mergeAll([{ value: id }, { value }, { type, id }])
    })

    // Checks whether user chose atleast one recipient
    if (!recipients.length) {
      return Rx.Observable.of(
        buildAction(ActionTypes.REPORTS_VALID_SHARE_FAILURE),
        buildAction(ActionTypes.SHARE_REPORT_UPDATE_STEP, { stepNumber: 2 }),
      )
    }

    return Rx.Observable.fromPromise(
      shareReport({
        id,
        message,
        recipients,
        shareAttachment,
      }),
    )
      .switchMap(() =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.REPORTS_SHARE_SUCCESS)),
          Rx.Observable.of(buildAction(ActionTypes.SHARE_REPORT_UPDATE_STEP, { stepNumber: 4 })),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.REPORTS_SHARE_FAILURE)),
          Rx.Observable.of(buildAction(ActionTypes.SHARE_REPORT_UPDATE_STEP, { stepNumber: 5 })),
        ),
      )
  })
// Add refetch after deleting report
const deleteReportNotificationEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.DELETE_REPORT_NOTIFICATION).switchMap(({ payload }) => {
    const { id } = payload

    return Rx.Observable.fromPromise(deleteReportNotification({ id }))
      .switchMap(() =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.NOTIFICATION_DOWNLOAD_MODAL_CLOSE)),
          Rx.Observable.of(buildAction(ActionTypes.DELETE_REPORT_NOTIFICATION_SUCCESS, { id })),
        ),
      )
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() =>
        Rx.Observable.concat(
          Rx.Observable.of(buildAction(ActionTypes.NOTIFICATION_DOWNLOAD_MODAL_CLOSE)),
          Rx.Observable.of(buildAction(ActionTypes.DELETE_REPORT_NOTIFICATION_FAILURE)),
        ),
      )
  })

const deleteReportEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.DELETE_REPORT).switchMap(({ payload }) => {
    const { id } = payload

    return Rx.Observable.fromPromise(deleteReport({ id }))
      ?.map(() => buildAction(ActionTypes.DELETE_REPORT_SUCCESS, { id }))
      .catch(logOutOnExpiredToken)
      .catch(serverIsDown)
      .catch(() => Rx.Observable.of(buildAction(ActionTypes.DELETE_REPORT_FAILURE)))
  })

const reportsResetEpic = (action$: any, { getState }: any) =>
  action$.ofType(ActionTypes.REPORTS_MODAL_CLOSE).switchMap(() => {
    const state = getState()
    const updateSoMe = getUpdateSoMe(state)

    if (updateSoMe) {
      return Rx.Observable.empty()
    }

    return Rx.Observable.of(
      buildAction(ActionTypes.REPORTS_RESET, {
        soft: getState().reports.step <= REPORT_STEP_CREATE_REPORT,
        // preserve some metadata (soft reset) if modal closed before `generate report`
      }),
    ).delay(500)
  })

const reportsUpdatedSoMeResetEpic = (action$: any, { getState }: any) =>
  Rx.Observable.combineLatest(
    action$.ofType(ActionTypes.REPORTS_MODAL_CLOSE).take(1),
    action$.ofType(ActionTypes.UPDATED_SOME_META_DATA_SUCCESS).take(1),
  )
    .switchMap(() =>
      Rx.Observable.of(
        buildAction(ActionTypes.REPORTS_RESET, {
          soft: getState().reports.step <= REPORT_STEP_CREATE_REPORT,
          // preserve some metadata (soft reset) if modal closed before `generate report`
        }),
      ),
    )
    .delay(500)

export default [
  deleteReportEpic,
  deleteReportNotificationEpic,
  reportLoadInitialEpic,
  reportsCreateEpic,
  reportsCreateFinishEpic,
  reportsCustomSortEpic,
  reportsHistoryEpic,
  reportsLoadEpic,
  reportsOpenModalEpic,
  reportsResetEpic,
  reportsUpdatedSoMeResetEpic,
  reportsSaveCustomSortOnExitEpic,
  reportsSourceEpic,
  reportsStepEpic,
  reportsTagSortEpic,
  reportsTitleEpic,
  shareReportEpic,
  reportsHistorySuccessEpic,
  reportUpdateSoMeMetadata,
]
